import "./App.css";
import React, { Suspense, useEffect } from "react";
import RoutesComponent from "./routes/RoutesComponent";
import { Provider } from "react-redux";
import { store } from "./store";
import Spinner from "./componants/Spinner";
import FallbackSpinner from "./componants/FallbackSpinner";
import Toaster from "./componants/Toaster";

function App() {

  useEffect(() => {
    return () => sessionStorage.removeItem('userId');
  }, [])
  // console.log("hello New");
  if (process.env.REACT_APP_CUSTOM_VARIABLE === "development_value") {
    console.log = () => { };
  }
  return (
    <Suspense fallback={<FallbackSpinner />}>
      <Provider store={store}>
        <RoutesComponent />
        <Toaster />
        <Spinner />
      </Provider>
    </Suspense>
  );
}

export default App;
